<template>
  <div class="not-found">
    <h1>404</h1>
    <h2>Page not found</h2>
    <p>
      The page you are looking for does not exist.
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>

</style>
